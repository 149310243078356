<template>
  <div>
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        
      </el-col>
    </el-row>
    <el-button
      type="primary"
      v-if="!userInfo.shop_id"
      class="mb10"
      size="mini"
      @click="openAddImg()"
    >上传图片</el-button>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
          @selection-change=""
        >
          <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
          <ElTableColumn label="图片编号" prop="id" />
          <ElTableColumn label="预览">
            <template slot-scope="{ row }">
              <el-image
                :src="row.url"
              ></el-image>
            </template>
          </ElTableColumn>
          <ElTableColumn label="说明" prop="title" />
          <ElTableColumn label="链接" prop="target_url" />

          <ElTableColumn label="操作" width="180" fixed="right">
            <template slot-scope="{ row }">

              <el-switch
                v-model="row.use_status"
                @change="changeStatus($event,row.id)"
              >
              </el-switch>
              
            </template>
            

          </ElTableColumn>
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <el-dialog title="上传图片" :visible.sync="addImg" width="500px">
      <el-form
        ref="imgDetail"
        label-width="100px"
        :rules="rulesImg"
        :model="imgDetail"
        size="mini"
      >
        <el-form-item label="图片说明:" prop="title">
          <el-input v-model="imgDetail.title" class="w300" placeholder="请填写图片说明" type="textarea" />
        </el-form-item>
        
        <el-form-item label="跳转链接:" prop="target_url">
          <el-input v-model="imgDetail.target_url" class="w300" placeholder="请填写图片跳转链接" type="text" />
        </el-form-item>

        <el-form-item label="上传图片:"> <!--http://burn.178590.com/mini/file/bannerFile-->
          <el-upload
            action="https://sunburn.178590.com/mini/file/bannerFile"
            list-type="picture-card"
            :limit="1"
            :file-list="fileList"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          
        </el-form-item>
        
        <div style="text-align: right;">
          <el-button type="primary" size="mini" @click="submitImg()">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" style="z-index:99;">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import imgUpload from "@/components/Upload/imgUpload";
import pagination from "@/components/Pagination";
import { bannerList, bannerUpload, changBannerStatus} from "@/api/sys";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  name = ""; // 客户名称
  phone = ""; // 客户电话
}
class ImgDetail {
  title = "";
  target_url = "";
  start_time = "";
  over_time = "";
  uploadList = [];
}
class RulesImg {
  title = [
    { required: true, message: "请填写图片说明", trigger: "change" }
  ];
}
export default {
  
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(), // 分页
      search: new Search(), // 检索条件
      list: [], // 数据展示
      idList: "",
      addImg: false, //图片上传弹框
      rulesImg: new RulesImg(),
      imgDetail: new ImgDetail(),
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      submitMsg: false,
      value1: false,
      value2: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      // if (option === "search") this.page = new Page();
      bannerList({
          ...this.search,
          ...this.page,
          type:1,
      }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },
    // 按钮
    tapBtn(routerName, row) {
      const query = {
        id: row.id,
        activeName: "first",
        customer_name: row.customer_name
      };
      this.$router.push({ name: routerName, query });
    },
    
    // 打开上传弹窗
    openAddImg(){
      this.addImg = true;
      this.imgDetail = new ImgDetail();
    },
    
    handleRemove(e) {
      this.imgDetail.uploadList.splice(0,1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleAvatarSuccess(res, file) {
      this.imgDetail.uploadList.push(res.data.file_path);
    },

    submitImg(){
      console.log('提交');
      console.log(this.imgDetail);

      

      bannerUpload({
        ...this.imgDetail
      }).then(res => {
        console.log(res);
        
        this.$message({
          type: "success",
          message: res.msg
        });
        this.addImg = false;
        // this.getDetail();
        this.getList();
      })
      

    },

    changeStatus(e,id){
      console.log(e);
      console.log(id);

      changBannerStatus({
        id:id,
        status_type:e,
      }).then(res => {
        console.log(res);

        this.$message({
          type: "success",
          message: res.msg
        });
      });

    }
    
  }
};
</script>
